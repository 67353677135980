@tailwind base;
@tailwind components;
@tailwind utilities;



@layer components { 

    .body {
        @apply font-sans text-base text-gray-900 antialiased;
    }

    .btn-common {
        @apply m-1 p-1 flex rounded-lg justify-center items-center cursor-pointer bg-slate-600 hover:bg-cyan-600 text-white hover:text-yellow-300 font-bold;
    }
    .image-button {
        @apply relative inset-0 border-none p-10 bg-none text-white text-xl text-center;
    }
    .image-button-content {
        @apply w-full h-full bg-cover;
    }
    .image-button .image-button-content::after {
        @apply absolute inset-0 bg-black bg-opacity-50 flex justify-center items-center top-0 left-0 w-full h-full;
    }
    .input-common {
        @apply m-1 border-none rounded-lg p-2 bg-gray-100 focus:outline-none focus:ring-2 focus:ring-cyan-600 focus:ring-opacity-50;
    }
}

