.btcpay-form {
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.btcpay-form--inline {
    flex-direction: row;
}

.btcpay-form--block {
    flex-direction: column;
}

.btcpay-form--inline .submit {
    margin-left: 15px;
}

.btcpay-form--block select {
    margin-bottom: 10px;
}

.btcpay-form .btcpay-custom-container {
    text-align: center;
}

.btcpay-custom {
    display: flex;
    align-items: center;
    justify-content: center;
}

.btcpay-form .plus-minus {
    cursor: pointer;
    font-size: 25px;
    line-height: 25px;
    background: #DFE0E1;
    height: 30px;
    width: 45px;
    border: none;
    border-radius: 60px;
    margin: auto 5px;
    display: inline-flex;
    justify-content: center;
}

.btcpay-form select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background: #333;
    border: 1px solid transparent;
    display: block;
    padding: 1px;
    margin-left: auto;
    margin-right: auto;
    font-size: 11px;
    cursor: pointer;
}

.btcpay-form select:hover {
    border-color: #333;
}

.btcpay-form option {
    color: #ffffff;
    background: rgba(113, 113, 113, 0.1);
}

.btcpay-input-price {
    -moz-appearance: textfield;
    border: none;
    box-shadow: none;
    text-align: center;
    margin-bottom: 1px;
    background: #fff;
    width: 100px;
}

.btcpay-input-price:focus {
    border-color: #ffaa00;
    border-width: 2px;
}

.btcpay-input-price::-webkit-outer-spin-button,
.btcpay-input-price::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}